import { EstimateGuidContainer, ForCompany } from '@breezy/shared'
import { useEffect } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { LoadingSpinner } from '../../components/LoadingSpinner'

import { useIsLdLoaded } from '../../hooks/useFeatureFlags'
/** @deprecated */
const EstimateReviewPageLoader = ({
  estimateGuid,
  companyGuid,
}: ForCompany<EstimateGuidContainer>) => {
  const ldLoaded = useIsLdLoaded()
  const navigate = useNavigate()

  useEffect(() => {
    if (ldLoaded) {
      navigate('/estimate/' + estimateGuid)
    }
  }, [estimateGuid, ldLoaded, navigate])

  if (!ldLoaded) {
    return <LoadingSpinner />
  }

  return <></>
}

/** @deprecated */
const ConsumerEstimateReviewPage = () => {
  const { id } = useLoaderData() as { id: string }
  const idParts = id.split('_')
  const companyGuid = idParts[0]
  const estimateGuid = idParts[1]

  return (
    <EstimateReviewPageLoader
      estimateGuid={estimateGuid}
      companyGuid={companyGuid}
    />
  )
}

export default ConsumerEstimateReviewPage
