import cn from 'classnames'
import React, { useCallback, useState } from 'react'

import {
  faCreditCard,
  faHandHoldingDollar,
} from '@fortawesome/pro-light-svg-icons'
import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import Switch from '../../elements/Switch/Switch'
import useIsMobile, { useIsMediumScreen } from '../../hooks/useIsMobile'
import { useStrictContext } from '../../utils/react-utils'
import { IssueLoading } from './NotFound'
import { SelfServeCreditCardPayment } from './SelfServeCreditCardPayment/SelfServeCreditCardPayment'
import SelfServeWisetackFinancing from './SelfServeWisetackFinancing/SelfServeWisetackFinancing'
import { SelfServePaymentFormContext } from './selfServePaymentUtils'

type SuccessInfoRowProps = React.PropsWithChildren<{ label: string }>

const SuccessInfoRow = React.memo<SuccessInfoRowProps>(
  ({ label, children }) => (
    <div className="mb-2 flex w-full flex-row items-center text-base text-bz-gray-1000">
      <div className="flex-1 font-semibold">{label}</div>
      <div>{children}</div>
    </div>
  ),
)

const PaymentCompleted = React.memo(() => {
  const { invoice } = useStrictContext(SelfServePaymentFormContext)

  return (
    <div className="flex flex-col items-center">
      <div className="mb-4 flex h-[72px] w-[72px] items-center justify-center rounded-full border-8 border-solid border-daybreak-blue-100 bg-daybreak-blue-200">
        <FontAwesomeIcon icon={faCheck} className="text-4xl text-bz-primary" />
      </div>
      <div className="mb-6 text-3xl font-semibold">Payment completed</div>
      <SuccessInfoRow label="Invoice number">
        #{invoice.displayId}
      </SuccessInfoRow>
      <SuccessInfoRow label="Payment Method">
        {invoice.paymentsSummary.paymentTypesDescription ?? 'Credit Card'}
      </SuccessInfoRow>
    </div>
  )
})

type PaymentOptionSelectProps = {
  selectedPaymentType: SelfServePaymentOption
  selectPaymentType: (paymentType: SelfServePaymentOption) => void
}
const PaymentOptionSelect = React.memo<PaymentOptionSelectProps>(
  ({ selectPaymentType, selectedPaymentType }) => {
    return (
      <div className="mb-5 flex flex-row gap-3">
        {(
          [
            {
              icon: faCreditCard,
              description: 'Credit Card',
              paymentType: 'credit-card',
            },
            {
              icon: faHandHoldingDollar,
              description: 'Financing',
              paymentType: 'financing',
            },
          ] as const
        ).map(({ icon, description, paymentType }) => (
          <div
            key={paymentType}
            className={cn(
              'text-md flex h-[44px] flex-1 cursor-pointer items-center space-x-2 rounded-[4px] border border-solid border-bz-gray-500 px-3 font-semibold',
              {
                'border border-solid border-bz-primary bg-daybreak-blue-100 text-bz-primary':
                  selectedPaymentType === paymentType,
              },
            )}
            onClick={() => selectPaymentType(paymentType)}
          >
            <FontAwesomeIcon className="h-[28px] w-[28px]" icon={icon} />
            <div>{description}</div>
          </div>
        ))}
      </div>
    )
  },
)
type SelfServePaymentFormProps = {
  onClose?: () => void
}

type SelfServePaymentOption = 'credit-card' | 'financing'

export const SelfServePaymentForm = React.memo<SelfServePaymentFormProps>(
  ({ onClose }) => {
    const { wisetackEnabled, paymentCompleted, didPaymentSucceed } =
      useStrictContext(SelfServePaymentFormContext)

    const showPaymentCompleted = didPaymentSucceed || paymentCompleted
    const isMobile = useIsMobile()
    const isMediumScreen = useIsMediumScreen()

    const [selectedPaymentType, setSelectedPaymentType] =
      useState<SelfServePaymentOption>('credit-card')

    const selectPaymentType = useCallback(
      (paymentType: SelfServePaymentOption) => {
        setSelectedPaymentType(paymentType)
      },
      [],
    )

    return (
      <div className="flex h-full flex-1 flex-col bg-[#F1F5F9]">
        <div
          className={classNames(
            'relative flex flex-1 flex-col items-center justify-start overflow-auto text-bz-gray-900',
            {
              'p-4': isMobile,
            },
          )}
        >
          {onClose && isMediumScreen && !didPaymentSucceed && (
            <Button
              ghost
              size="large"
              className={classNames(
                'flex items-center justify-center border-bz-gray-500 text-bz-gray-900',
                isMobile ? 'absolute right-4' : 'fixed right-6 top-14',
              )}
              shape="circle"
              onClick={onClose}
            >
              <FontAwesomeIcon icon={faXmark} />
            </Button>
          )}
          <div
            className={classNames('my-14 rounded-md bg-white p-6 shadow-md', {
              'w-[480px] max-w-[480px]': !isMobile,
            })}
          >
            {showPaymentCompleted ? (
              <PaymentCompleted />
            ) : (
              <>
                {wisetackEnabled && (
                  <PaymentOptionSelect
                    selectedPaymentType={selectedPaymentType}
                    selectPaymentType={selectPaymentType}
                  />
                )}
                <Switch value={selectedPaymentType}>
                  {{
                    'credit-card': () => <SelfServeCreditCardPayment />,
                    financing: () => <SelfServeWisetackFinancing />,
                    default: () => <IssueLoading />,
                  }}
                </Switch>
              </>
            )}
          </div>
        </div>
      </div>
    )
  },
)
