/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import {
  httpBatchLink,
  httpLink,
  splitLink,
  TRPCClientError,
} from '@trpc/client'
import { useCallback, useState } from 'react'
import { useErrorModal } from '../components/ErrorModal/ErrorModal'
import { getConfig } from '../config'
import { trpc } from '../hooks/trpc'
import { Composable } from '../utils/Composable'
import { toTrpcUiError } from '../utils/TrpcErrorMessages'

const config = getConfig()

export const NonAuthTrpcWrapper = ({ children }: Composable) => {
  const errorModal = useErrorModal()
  const onMutationError = useCallback(
    (error: unknown) => {
      if (error instanceof TRPCClientError)
        errorModal.pushError(toTrpcUiError(error, 'execute'))
    },
    [errorModal],
  )
  const onQueryError = useCallback(
    (error: unknown) => {
      if (error instanceof TRPCClientError)
        errorModal.pushError(toTrpcUiError(error, 'query'))
    },
    [errorModal],
  )
  const queryClientOptions = {
    defaultOptions: {
      queries: { onError: onQueryError },
      mutations: { onError: onMutationError },
    },
  }

  const [queryClient] = useState(() => new QueryClient(queryClientOptions))
  const [trpcClient] = useState(() => {
    const url = `${config.apiUrl}/trpc`
    return trpc.createClient({
      transformer: {
        input: {
          serialize: (t: any): any => t,
          deserialize: (t: any): any => t,
        },
        output: {
          serialize: (t: any): any => t,
          deserialize: (t: any): any => t,
        },
      },
      // https://trpc.io/docs/v9/links#disable-batching-for-certain-requests
      links: [
        splitLink({
          condition(op) {
            // check for context property `skipBatch`
            return op.context.skipBatch === true
          },
          // when condition is true, use normal request
          true: httpLink({
            url,
            headers: async () => {
              return {
                Auth: 'non-auth',
              }
            },
          }),
          // when condition is false or undefined, use batching
          false: httpBatchLink({
            url,
            headers: async () => {
              return {
                Auth: 'non-auth',
              }
            },
          }),
        }),
      ],
    })
  })

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  )
}
