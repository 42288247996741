import { BzDateFns, formatMoney } from '@breezy/shared'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import React from 'react'
import { DetailRow } from '../../elements/StatusCard/DetailRow'
import { StatusCard } from '../../elements/StatusCard/StatusCard'
import useIsMobile from '../../hooks/useIsMobile'
import { useStrictContext } from '../../utils/react-utils'
import { InvoiceContext } from '../Invoices/invoiceUtils'
import { SelfServePaymentFormContext } from './selfServePaymentUtils'

export const InvoiceAlreadyPaidCard = React.memo(() => {
  const { displayId } = useStrictContext(InvoiceContext)
  const { invoice, tzId } = useStrictContext(SelfServePaymentFormContext)

  const isMobile = useIsMobile()

  return (
    <StatusCard
      className={isMobile ? 'min-w-[300px]' : undefined}
      type="success"
      icon={faCheck}
      title="Invoice Paid"
    >
      <DetailRow
        label="Amount"
        value={formatMoney(invoice.paymentsSummary.totalPaidAmountUsd)}
      />
      <DetailRow
        label="Payment Method"
        value={invoice.paymentsSummary.paymentTypesDescription}
      />
      {invoice.paymentsSummary.lastPaidAt && (
        <DetailRow
          label="Payment Date"
          value={BzDateFns.formatFromISO(
            invoice.paymentsSummary.lastPaidAt,
            'MMM dd, yyyy',
            tzId,
          )}
        />
      )}
      <DetailRow label="Invoice ID" value={`#${displayId}`} />
    </StatusCard>
  )
})
