import { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { getConfig } from '../../config'

const config = getConfig()

export const ConfirmationPage = () => {
  const { id } = useLoaderData() as { id: string }

  const [confirmationState, updateConfirmationState] = useState<
    'loading' | 'success' | 'error'
  >('loading')

  useEffect(() => {
    ;(async () => {
      try {
        const res = await fetch(
          `${config.apiUrl}/public/appointment-confirmations/${id}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              jobAppointmentConfirmed: true,
            }),
          },
        )
        const resJson = await res.json()
        if (resJson.responseMessage?.startsWith('Failed')) {
          throw new Error(resJson.responseMessage)
        }
        return updateConfirmationState('success')
      } catch (e) {
        console.error(
          `There was an error updating the confirmation for reference number ${id}`,
          e,
        )
        updateConfirmationState('error')
      }
    })()
  }, [id])

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      {confirmationState === 'loading' && (
        <div>
          Confirming your appointment for Reference number:{' '}
          <strong>{id}</strong>
        </div>
      )}
      {confirmationState === 'success' && (
        <div>
          Appointment <strong>{id}</strong> has been confirmed.
        </div>
      )}
      {confirmationState === 'error' && (
        <div>
          There was an error confirming appointment with reference number:{' '}
          <strong>{id}</strong>
        </div>
      )}
    </div>
  )
}
