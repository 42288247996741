import { LoanStatus, formatUsc, usdToUsCents } from '@breezy/shared'
import { Button, Divider } from 'antd'
import cn from 'classnames'
import React, { useMemo } from 'react'
import LoanPromoDisclaimer from '../../../components/Financing/LoanPromo/LoanPromoDisclaimer'
import LoanPromo from '../../../components/Financing/LoanPromo/LoanPromoView'
import { WisetackLogo } from '../../../components/Financing/WisetackLogo'
import Switch from '../../../elements/Switch/Switch'
import { useIsSmallScreen } from '../../../hooks/useIsMobile'
import { useStrictContext } from '../../../utils/react-utils'
import { SimpleLoanRecord } from '../LoanRecordSubscription.anon-gql'
import { useEmbeddedLoanApplication } from '../hooks/useEmbeddedLoanApplication'
import { SelfServePaymentFormContext } from '../selfServePaymentUtils'
type LoanStatusMessageProps = React.PropsWithChildren<{
  status?: LoanStatus
}>

const LoanStatusMessage = React.memo<LoanStatusMessageProps>(
  ({ children, status }) => {
    const className = useMemo(() => {
      switch (status) {
        case 'DECLINED':
          return 'bg-bz-red-100 border border-solid border-bz-red-300'
        case 'CANCELED':
          return 'bg-bz-gray-300 border border-solid border-bz-gray-500'
        default:
          return 'bg-bz-green-100 border border-solid border-bz-green-300'
      }
    }, [status])

    return (
      <div
        className={cn(
          'flex items-center gap-3 rounded-lg px-3 py-4',
          className,
        )}
      >
        <div className="block w-[36px]">
          <WisetackLogo size="md" />
        </div>
        {children}
      </div>
    )
  },
)

type ApprovedLoanStatusMessageProps = LoanStatusMessageProps & {
  approvedMessage?: string
}
const ApprovedLoanStatusMessage = React.memo<ApprovedLoanStatusMessageProps>(
  ({ approvedMessage, children }) => {
    return (
      <LoanStatusMessage>
        <p className="mb-0">
          Great news! You've been <strong>{approvedMessage}</strong>
          {children}
        </p>
      </LoanStatusMessage>
    )
  },
)

type AuthorizedLoanStatusMessageProps = {
  loanRecord: SimpleLoanRecord
}

const AuthorizedLoanStatusMessage = React.memo<
  React.PropsWithChildren<AuthorizedLoanStatusMessageProps>
>(({ loanRecord, children }) => {
  const isPartiallyApproved = useMemo(() => {
    if (!loanRecord.approvedLoanAmountUsc) return false

    return loanRecord.approvedLoanAmountUsc < loanRecord.requestedLoanAmountUsc
  }, [loanRecord.approvedLoanAmountUsc, loanRecord.requestedLoanAmountUsc])

  if (!loanRecord.approvedLoanAmountUsc) return null

  return isPartiallyApproved ? (
    <ApprovedLoanStatusMessage
      approvedMessage={`partially approved for ${formatUsc(
        loanRecord.approvedLoanAmountUsc,
      )}!`}
    >
      {children}
    </ApprovedLoanStatusMessage>
  ) : (
    <ApprovedLoanStatusMessage
      approvedMessage={`approved for ${formatUsc(
        loanRecord.approvedLoanAmountUsc,
      )}!`}
    >
      {children}
    </ApprovedLoanStatusMessage>
  )
})

const LoanStatusSummary = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    return <div className="text-[16px] font-semibold">{children}</div>
  },
)

export const SelfServeWisetackFinancing = React.memo(() => {
  const { invoice, companyName, loanRecord, loanApplicationStarted } =
    useStrictContext(SelfServePaymentFormContext)

  const loanStatus = useMemo(
    () => loanRecord?.wisetackLoanStatuses[0].loanStatus,
    [loanRecord?.wisetackLoanStatuses],
  )

  const { embeddedLoanApplication, toggle } = useEmbeddedLoanApplication()

  const isSmallScreen = useIsSmallScreen()

  return (
    <div className="flex flex-col gap-y-3">
      <Switch value={loanStatus}>
        {{
          INITIATED: () => (
            <>
              <LoanStatusSummary>
                Continue your financing application through Wisetack
              </LoanStatusSummary>
              {loanRecord && (
                <LoanStatusMessage>
                  <p className="mb-0">
                    You have started a loan application through Wisetack for a{' '}
                    <strong>
                      requested amount of{' '}
                      {formatUsc(loanRecord.requestedLoanAmountUsc)}
                    </strong>
                    . If you would like to pay with a different payment option,
                    please reach out to <strong>{companyName}</strong>
                  </p>
                </LoanStatusMessage>
              )}
              <Divider className="my-4" />
              <Button type="primary" size="large" onClick={toggle}>
                Continue Application
              </Button>
            </>
          ),
          AUTHORIZED: () => (
            <>
              <LoanStatusSummary>
                Finish your financing application through Wisetack
              </LoanStatusSummary>
              {loanRecord && (
                <AuthorizedLoanStatusMessage loanRecord={loanRecord} />
              )}
              <Divider className="my-4" />
              <Button type="primary" size="large" onClick={toggle}>
                Accept Terms
              </Button>
            </>
          ),
          LOAN_TERMS_ACCEPTED: () => (
            <>
              <LoanStatusSummary>
                Finish your financing application through Wisetack
              </LoanStatusSummary>
              {loanRecord && (
                <AuthorizedLoanStatusMessage loanRecord={loanRecord}>
                  {' '}
                  <span>
                    Please release the funds to complete the payment of the
                    invoice.
                  </span>
                </AuthorizedLoanStatusMessage>
              )}
              <Divider className="my-4" />
              <img
                alt="Sync with Quickbooks"
                src="https://d3j5nzmmhjc8v6.cloudfront.net/wisetack-release-payment.png"
                className="w-full"
              />
              <Button
                className="mt-2"
                type="primary"
                size="large"
                onClick={toggle}
              >
                Release Payment
              </Button>
            </>
          ),
          LOAN_CONFIRMED: () => (
            <>
              <LoanStatusSummary>
                You have released the payment of your Wisetack loan
              </LoanStatusSummary>
              <LoanStatusMessage>
                <p className="mt-3">
                  Pay for the remaining balance of your invoice with a credit
                  card or reach out to <strong>{companyName}</strong> to begin a
                  new loan application.
                </p>
              </LoanStatusMessage>
            </>
          ),
          SETTLED: () => (
            <>
              <LoanStatusSummary>
                You have released the payment of your Wisetack loan
              </LoanStatusSummary>
              <LoanStatusMessage>
                <p className="mt-3">
                  Pay for the remaining balance of your invoice with a credit
                  card or reach out to <strong>{companyName}</strong> to begin a
                  new loan application.
                </p>
              </LoanStatusMessage>
            </>
          ),
          DECLINED: () => (
            <>
              <LoanStatusSummary>
                Apply for financing through Wisetack
              </LoanStatusSummary>
              <LoanStatusMessage status="DECLINED">
                <p className="mb-0">
                  Unfortunately, you were not approved for financing. If you'd
                  like to try with someone else's information you'll need to
                  reach out to <strong>{companyName}</strong> to send a new
                  payment link.
                </p>
              </LoanStatusMessage>
            </>
          ),
          CANCELED: () => (
            <>
              <LoanStatusSummary>
                Apply for financing through Wisetack
              </LoanStatusSummary>
              <LoanStatusMessage status="CANCELED">
                <p className="mb-0">
                  Your loan application has been canceled. If you'd like to try
                  again, you'll need to reach out to{' '}
                  <strong>{companyName}</strong> to send a new payment link.
                </p>
              </LoanStatusMessage>
            </>
          ),
          default: () => (
            <>
              <LoanStatusSummary>
                Apply for financing through Wisetack
              </LoanStatusSummary>
              <LoanPromo
                amountUsc={usdToUsCents(invoice.totalPriceUsd)}
                onQualify={toggle}
                qualifyText={
                  loanApplicationStarted ? 'Continue' : 'Qualify Now'
                }
                showLearnMore
              />
              {isSmallScreen && (
                <LoanPromoDisclaimer
                  amountUsc={usdToUsCents(invoice.totalPriceUsd)}
                />
              )}
            </>
          ),
        }}
      </Switch>
      {embeddedLoanApplication}
    </div>
  )
})

export default SelfServeWisetackFinancing
