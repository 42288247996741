import { formatMoney } from '@breezy/shared'
import { Button } from 'antd'
import cn from 'classnames'
import React, { useCallback, useState } from 'react'
import useIsMobile from '../../hooks/useIsMobile'
import { useStrictContext } from '../../utils/react-utils'
import { SelfServePaymentFormV2 } from './SelfServePaymentFormV2'
import { SelfServePaymentFormContext } from './selfServePaymentUtils'

export const SelfServePaymentPayButtonFooter = React.memo(() => {
  const { invoice, wisetackEnabled, paymentCompleted } = useStrictContext(
    SelfServePaymentFormContext,
  )

  const [showForm, setShowForm] = useState(false)

  const onClose = useCallback(() => setShowForm(false), [])

  const isMobile = useIsMobile()
  return showForm || paymentCompleted ? (
    <div className="fixed inset-0 z-10">
      <SelfServePaymentFormV2
        onClose={paymentCompleted ? undefined : onClose}
      />
    </div>
  ) : (
    <div
      className={cn(
        'footer-pay-button-shadow fixed inset-x-0 bottom-0 z-10 flex justify-center bg-[#F1F5F9] px-4 py-3',
        {
          'pb-6': isMobile,
        },
      )}
    >
      <Button
        block
        size="large"
        type="primary"
        className="max-w-[400px]"
        onClick={() => setShowForm(true)}
      >
        {wisetackEnabled
          ? 'View Payment Options'
          : `Pay ${formatMoney(invoice.paymentsSummary.amountDueUsd)}`}
      </Button>
    </div>
  )
})
