import './common/wdyr' // Developer tool to help understand why a component is rendering in non-production environments

import React, { useMemo } from 'react'
import {
  LoaderFunction,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom'

import { ConfirmationPage } from '../pages/ConsumerAppointmentConfirmationPage/ConsumerAppointmentConfirmationPage'
import ConsumerEstimateReviewPage from '../pages/ConsumerEstimateReviewPage/ConsumerEstimateReviewPage'
import { NonAuthTrpcWrapper } from '../providers/NonAuthTrpcWrapper'
import { withApp } from './common/CommonAppWrapper'

import { ReviewEstimatePage } from '../pages/EstimatesFlow/CustomerExperience/ReviewEstimatePage'
import { ReviewInvoicePage } from '../pages/Invoices/CustomerExperience/ReviewInvoicePage'
import { SelfServePaymentPage } from '../pages/SelfServePaymentPage/SelfServePaymentPage'
import { initializeDatadogRum } from '../providers/AnalyticsWrapper'
import { AppContextWrapper } from '../providers/AppContextWrapper'
import { NonAuthLaunchDarklyWrapper } from '../providers/LaunchDarklyWrapper'
import { PlatformVersionWrapper } from '../providers/PlatformVersionWrapper'
import { NonAuthUrqlWrapper } from '../providers/UrqlWrapper'
import { Applications } from '../utils/application-type'
import './AccountExperience.less'

initializeDatadogRum()

const idLoader: LoaderFunction = ({ params }) => ({
  id: params.id,
})

const AccountExperienceApp = React.memo(() => {
  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          path: '/',
          children: [
            {
              path: 'appointment-confirmation/:id',
              element: <ConfirmationPage />,
              loader: idLoader,
            },
            {
              path: 'estimate-review/:id',
              element: <ConsumerEstimateReviewPage />,
              loader: idLoader,
            },
            {
              path: 'estimate/:estimateGuid',
              element: <ReviewEstimatePage />,
            },
            {
              path: 'estimate/:estimateGuid/:contactGuid',
              element: <ReviewEstimatePage />,
            },
          ],
        },
        {
          path: '/customer-payment/:invoiceReferenceNumber',
          element: <SelfServePaymentPage />,
        },
        {
          path: '/customer-payment/:invoiceReferenceNumber/:contactGuid',
          element: <SelfServePaymentPage />,
        },
        {
          path: '/invoice/:invoiceGuid',
          element: <ReviewInvoicePage />,
        },
        {
          path: '/invoice/:invoiceGuid/:contactGuid',
          element: <ReviewInvoicePage />,
        },
        {
          path: '*',
          element: <div>404. You might be lost</div>,
        },
      ]),
    [],
  )

  return <RouterProvider router={router} />
})

withApp(
  <NonAuthLaunchDarklyWrapper>
    <PlatformVersionWrapper>
      <NonAuthTrpcWrapper>
        <NonAuthUrqlWrapper>
          <AppContextWrapper appType={Applications.ACCOUNT}>
            <AccountExperienceApp />
          </AppContextWrapper>
        </NonAuthUrqlWrapper>
      </NonAuthTrpcWrapper>
    </PlatformVersionWrapper>
  </NonAuthLaunchDarklyWrapper>,
)
