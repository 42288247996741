import {
  BzDateFns,
  CartItemType,
  Guid,
  InvoiceCartItem,
  LOAN_PAYMENT_COMPLETED_STATUSES,
  R,
  calculateInvoiceTotals,
  invoiceV2ToInvoiceV1Status,
  noOp,
  paymentMethodDisplayName,
  paymentRecordsToInvoicePayments,
  usCentsToUsd,
} from '@breezy/shared'
import { faDownload } from '@fortawesome/pro-light-svg-icons'
import { faEllipsis, faPrint } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import { useSubscription } from 'urql'
import { z } from 'zod'
import {
  MobileActionBar,
  MobileActionBarButton,
} from '../../../adam-components/ActionBar/MobileActionBar'
import { LoanPromoShortDisclaimer } from '../../../components/Financing/LoanPromo/LoanPromoDisclaimer'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { PaymentStatusProvider } from '../../../components/PaymentWorkflow/providers/PaymentStatusProvider'
import { useIsMediumScreen } from '../../../hooks/useIsMobile'
import { UnauthPricebookPhotosEnabledProvider } from '../../../hooks/useIsPricebookPhotosEnabled'
import { FinancialConfigContext } from '../../../providers/CompanyFinancialConfigWrapper'
import { useModalState, useStrictContext } from '../../../utils/react-utils'
import { SelfServePaymentFormV2 } from '../../SelfServePaymentPage/SelfServePaymentFormV2'
import { SelfServePaymentPayButtonFooter } from '../../SelfServePaymentPage/SelfServePaymentPayButtonFooter'
import {
  SelfServePaymentFormContext,
  SelfServePaymentFormContextInvoice,
} from '../../SelfServePaymentPage/selfServePaymentUtils'
import { InvoiceContent } from '../components/InvoiceContent'
import {
  InvoiceContext,
  InvoiceDataContext,
  InvoiceDataContextType,
  InvoiceInfo,
  useFetchedDiscountsToInvoiceDiscounts,
  useIsInvoiceFinanceable,
} from '../invoiceUtils'
import {
  ANON_RELEVANT_INVOICE_DATA_SUBSCRIPTION,
  ANON_REVIEW_INVOICE,
  ReviewInvoice,
  SimpleLoanRecord,
} from './ReviewInvoice.anon-gql'
import ReviewInvoiceActionModal, {
  useDownloadReviewInvoice,
  usePrintReviewInvoice,
} from './ReviewInvoiceActionModal'

const NotFound = () => (
  <div className="flex h-screen w-screen items-center justify-center">
    <div className="text-center text-base font-semibold">
      The requested invoice cannot be found.
      <br />
      <br />
      If you believe you should be able to see this invoice, please request a
      fresh invoice link from your Pro.
    </div>
  </div>
)

type ReviewInvoicePageWithEverythingProps = {
  invoice: ReviewInvoice
}

const ReviewInvoicePageWithEverything =
  React.memo<ReviewInvoicePageWithEverythingProps>(({ invoice }) => {
    const isMediumScreen = useIsMediumScreen()

    const [actionsModalOpen, openActionsModal, closeActionsModal] =
      useModalState()

    const { downloadInvoicePDF, invoiceDownloading } =
      useDownloadReviewInvoice(invoice)

    const { beginOpenInvoice, invoiceGenerating } =
      usePrintReviewInvoice(invoice)

    const onDownloadPdf = useCallback(async () => {
      await downloadInvoicePDF()
    }, [downloadInvoicePDF])

    const onPrintInvoice = useCallback(async () => {
      await beginOpenInvoice()
    }, [beginOpenInvoice])
    const { invoiceTotals } = useStrictContext(InvoiceDataContext)
    const isFinanceable = useIsInvoiceFinanceable(invoice.status, invoiceTotals)
    return (
      <div
        className={classNames(
          'min-w-screen self-serve-payment-page flex min-h-screen items-stretch justify-center bg-[#F1F5F9] text-base *:min-w-0 *:flex-1',
          isMediumScreen ? 'flex-col' : 'flex-row',
        )}
      >
        <div
          className={classNames(
            'mx-auto flex max-w-[850px] flex-col bg-white',
            isMediumScreen ? 'p-4 pb-[76px]' : 'p-10',
          )}
        >
          <div
            className={classNames(
              'flex flex-row items-start gap-2 border-0 border-b border-solid border-bz-border-secondary',
              isMediumScreen ? 'mb-4 pb-4 *:flex-1 *:basis-0' : 'mb-6 pb-6',
            )}
          >
            <Button
              size="large"
              icon={<FontAwesomeIcon icon={faDownload} />}
              loading={invoiceDownloading}
              onClick={onDownloadPdf}
            >
              Download
            </Button>
            <Button
              size="large"
              icon={<FontAwesomeIcon icon={faPrint} />}
              loading={invoiceGenerating}
              onClick={onPrintInvoice}
            >
              Print
            </Button>
          </div>

          <InvoiceContent
            customerFacing
            noOuterBorder
            footer={
              <>
                {isFinanceable && (
                  <LoanPromoShortDisclaimer amountUsc={invoiceTotals.dueUsc} />
                )}
                {isMediumScreen && (
                  <MobileActionBar>
                    <MobileActionBarButton
                      icon={faEllipsis}
                      onClick={openActionsModal}
                    >
                      More
                    </MobileActionBarButton>
                  </MobileActionBar>
                )}
              </>
            }
          />
        </div>
        {actionsModalOpen && (
          <ReviewInvoiceActionModal
            invoice={invoice}
            onCancel={closeActionsModal}
          />
        )}
        {isMediumScreen ? (
          <SelfServePaymentPayButtonFooter />
        ) : (
          <SelfServePaymentFormV2 />
        )}
      </div>
    )
  })

type ReviewInvoicePageWithInvoiceDataProps = ReviewInvoicePageWithGuidProps & {
  invoice: ReviewInvoice
}

const ReviewInvoicePageWithInvoiceData =
  React.memo<ReviewInvoicePageWithInvoiceDataProps>(
    ({ invoiceGuid, invoice, contactGuid }) => {
      const lineItems = useMemo<InvoiceCartItem[]>(
        () =>
          invoice.invoiceCartItems.map(
            ({ cartItem: { photo, ...cartItem }, seq }) => ({
              ...cartItem,
              seq,
              description: cartItem.description ?? '',
              // None of these things are useful in readonly so I'm not fetching them. Putting dummy values in for
              // TypeScript
              itemGuid: '',
              itemType: CartItemType.SERVICE,
              photoGuid: photo?.photoGuid,
              photoCdnUrl: photo?.cdnUrl,
            }),
          ),
        [invoice.invoiceCartItems],
      )
      const discounts = useFetchedDiscountsToInvoiceDiscounts(
        invoice.invoiceDiscounts,
      )

      const info = useMemo<InvoiceInfo>(() => {
        return {
          ...invoice,
          customerPurchaseOrderNumber:
            invoice.customerPurchaseOrderNumber ?? '',
          billingAddress: invoice.billingAddress
            ? {
                type: 'adhoc',
                adHocAddress: invoice.billingAddress,
              }
            : {
                type: 'same_as_service',
              },
          resolvedBillingAddress: invoice.billingAddress,
          serviceContact:
            invoice.jobLink?.job.pointOfContact ??
            invoice.account.accountContacts[0].contact,
          // Business logic: an invoice won't have an issued or due date when it's a draft. It gets them once it's
          // un-draft-ed. Since you can't send an invoice link for a draft, I know these guys will exist.
          issuedAt: invoice.issuedAt ?? BzDateFns.nowISOString(),
          dueAt: invoice.dueAt ?? BzDateFns.nowISOString(),
          // I know based on business logic that this will always be defined
          serviceLocationGuid: invoice.locationLink?.locationGuid ?? '',
        }
      }, [invoice])

      const invoiceTotals = useMemo(
        () =>
          calculateInvoiceTotals(
            lineItems,
            invoice.taxRate,
            discounts,
            paymentRecordsToInvoicePayments(invoice.paymentRecords),
            invoice.dynamicPricingType,
          ),
        [
          discounts,
          invoice.dynamicPricingType,
          invoice.paymentRecords,
          invoice.taxRate,
          lineItems,
        ],
      )

      const paymentCompleted = useMemo(() => {
        return invoiceTotals.dueUsc === 0
      }, [invoiceTotals.dueUsc])

      const loanRecord = useMemo<SimpleLoanRecord | undefined>(() => {
        return invoice.wisetackLoanRecords[0]
      }, [invoice.wisetackLoanRecords])

      const loanApplicationStarted = useMemo(() => {
        return !!loanRecord?.loanApplicationLink
      }, [loanRecord?.loanApplicationLink])

      const loanPaymentSucceeded = useMemo(
        () =>
          !!loanRecord?.wisetackLoanStatuses[0].loanStatus &&
          LOAN_PAYMENT_COMPLETED_STATUSES.includes(
            loanRecord.wisetackLoanStatuses[0].loanStatus,
          ),
        [loanRecord?.wisetackLoanStatuses],
      )

      const [didPaymentSucceed, setDidPaymentSucceed] = useState(false)
      const setPaymentSuccessStatus = useCallback(
        (didPaymentSucceed: boolean) => {
          setDidPaymentSucceed(didPaymentSucceed)
        },
        [],
      )

      const [{ data: relevantData, fetching: fetchingRelevantData }] =
        useSubscription({
          query: ANON_RELEVANT_INVOICE_DATA_SUBSCRIPTION,
          variables: {
            companyGuid: invoice.companyGuid,
          },
        })

      const invoiceData = useMemo<InvoiceDataContextType>(
        () => ({
          messageHtml: invoice.messageHtml ?? '',
          taxRate: {
            // The name and guid aren't relevant for this readonly flow so I don't fetch them.
            pricebookTaxRateGuid: '',
            name: '',
            rate: invoice.taxRate,
          },
          info,
          lineItems,
          discounts,
          invoiceTotals,
          dynamicPricingType: invoice.dynamicPricingType,
        }),
        [
          discounts,
          info,
          invoice.dynamicPricingType,
          invoice.messageHtml,
          invoice.taxRate,
          invoiceTotals,
          lineItems,
        ],
      )

      const customAmountDueUsd = useSearchParam('amountDueUsd')

      const paymentAmountDueUsd = useMemo(() => {
        const invoiceAmountDueUsc = usCentsToUsd(invoiceTotals.dueUsc)
        const amountDueUsd = customAmountDueUsd
          ? parseFloat(customAmountDueUsd)
          : undefined

        if (amountDueUsd) {
          return Math.min(amountDueUsd, invoiceAmountDueUsc)
        }

        return invoiceAmountDueUsc
      }, [customAmountDueUsd, invoiceTotals.dueUsc])

      const paymentContextInvoice = useMemo<SelfServePaymentFormContextInvoice>(
        () => ({
          invoiceGuid,
          displayId: invoice.displayId,
          companyGuid: invoice.companyGuid,
          accountGuid: invoice.accountGuid,
          totalPriceUsd: usCentsToUsd(invoiceTotals.totalUsc),
          // Reference numbers aren't a thing in invoices V2. They are used by payments, however. A payment makes its
          // reference number `${invoice reference number}-${payment number}`. But it's just a string so we can just use
          // the display id instead.
          referenceNumber: `${invoice.displayId}`,
          status: invoiceV2ToInvoiceV1Status(invoice.status),
          paymentsSummary: {
            amountDueUsd: paymentAmountDueUsd,
            totalPaidAmountUsd: usCentsToUsd(invoiceTotals.paidUsc),
            paymentTypesDescription: R.uniq(
              invoice.paymentRecords.map(record =>
                paymentMethodDisplayName(record.paymentMethod),
              ),
            ).join(', '),
            lastPaidAt:
              invoice.paymentRecords.length > 0
                ? invoice.paymentRecords[0].occurredAt
                : undefined,
          },
          links: {
            jobGuid: invoice.jobLink?.jobGuid,
            maintenancePlanGuid:
              invoice.maintenancePlanLink?.maintenancePlanGuid,
          },
        }),
        [
          invoice.accountGuid,
          invoice.companyGuid,
          invoice.displayId,
          invoice.jobLink?.jobGuid,
          invoice.maintenancePlanLink?.maintenancePlanGuid,
          invoice.paymentRecords,
          invoice.status,
          invoiceGuid,
          invoiceTotals.paidUsc,
          invoiceTotals.totalUsc,
          paymentAmountDueUsd,
        ],
      )

      if (fetchingRelevantData) {
        return (
          <div className="flex h-screen w-screen items-center justify-center">
            <LoadingSpinner />
          </div>
        )
      }
      if (
        !(
          relevantData?.companiesByPk?.companyConfig &&
          relevantData?.companiesByPk?.billingProfile
        )
      ) {
        return <NotFound />
      }

      const wisetackEnabled =
        !!relevantData.companiesByPk.billingProfile.wisetackMerchantId &&
        !!contactGuid

      return (
        <PaymentStatusProvider>
          <InvoiceDataContext.Provider value={invoiceData}>
            <InvoiceContext.Provider
              value={{
                ...invoice,
                ...relevantData.companiesByPk,
                jobGuid: invoice.jobLink?.jobGuid,
                maintenancePlanGuid:
                  invoice.maintenancePlanLink?.maintenancePlanGuid,
                companyBlurb:
                  relevantData.companiesByPk.companyConfig.companyBlurb ?? '',
                ...relevantData.companiesByPk.billingProfile,
                businessEmail:
                  relevantData.companiesByPk.billingProfile.emailAddress
                    .emailAddress,
                businessPhoneNumber:
                  relevantData.companiesByPk.billingProfile.phoneNumber
                    .phoneNumber,
                disclaimer:
                  relevantData.companiesByPk.billingProfile.invoiceDisclaimer,
                // Not needed in readonly mode
                defaultInvoiceTerm: 'DUE_ON_RECEIPT',
                realPricebookItemGuidMap: {},
              }}
            >
              <SelfServePaymentFormContext.Provider
                value={{
                  invoiceGuid,
                  invoice: paymentContextInvoice,
                  companyGuid: invoice.companyGuid,
                  companyName: relevantData.companiesByPk.companyName,
                  wisetackEnabled,
                  paymentCompleted,
                  loanApplicationStarted,
                  loanPaymentSucceeded,
                  didPaymentSucceed,
                  contactGuid,
                  loanRecord,
                  tilledMerchantId:
                    relevantData.companiesByPk.billingProfile
                      .tilledMerchantAccountId ?? '',
                  setPaymentSuccessStatus,
                  tzId: relevantData.companiesByPk.tzId,
                  // This is there for the old version, but this version uses a live query so it shouldn't be necessary
                  refetchInvoice: noOp,
                }}
              >
                <FinancialConfigContext.Provider
                  value={{
                    merchantId:
                      relevantData.companiesByPk.billingProfile
                        .tilledMerchantAccountId ?? '',
                    wisetackEnabled,
                    wisetackMerchantId:
                      relevantData.companiesByPk.billingProfile
                        .wisetackMerchantId,
                  }}
                >
                  <ReviewInvoicePageWithEverything invoice={invoice} />
                </FinancialConfigContext.Provider>
              </SelfServePaymentFormContext.Provider>
            </InvoiceContext.Provider>
          </InvoiceDataContext.Provider>
        </PaymentStatusProvider>
      )
    },
  )

type ReviewInvoicePageWithGuidProps = {
  invoiceGuid: Guid
  contactGuid?: Guid
}

const ReviewInvoicePageWithGuid = React.memo<ReviewInvoicePageWithGuidProps>(
  ({ invoiceGuid, contactGuid }) => {
    const [{ data: invoiceData, fetching: fetchingInvoiceData }] =
      useSubscription({
        query: ANON_REVIEW_INVOICE,
        variables: {
          invoiceGuid,
        },
      })

    if (fetchingInvoiceData) {
      return (
        <div className="flex h-screen w-screen items-center justify-center">
          <LoadingSpinner />
        </div>
      )
    }
    if (!invoiceData?.invoicesByPk) {
      return <NotFound />
    }

    return (
      <UnauthPricebookPhotosEnabledProvider
        companyGuid={invoiceData.invoicesByPk.companyGuid}
      >
        <ReviewInvoicePageWithInvoiceData
          invoiceGuid={invoiceGuid}
          invoice={invoiceData.invoicesByPk}
          contactGuid={contactGuid}
        />
      </UnauthPricebookPhotosEnabledProvider>
    )
  },
)

export const ReviewInvoicePage = React.memo(() => {
  const { invoiceGuid, contactGuid } = useParams<{
    invoiceGuid?: string
    contactGuid?: string
  }>()

  if (!invoiceGuid) {
    return <NotFound />
  }

  if (!z.string().uuid().safeParse(invoiceGuid).success) {
    return <NotFound />
  }

  return (
    <ReviewInvoicePageWithGuid
      invoiceGuid={invoiceGuid}
      contactGuid={contactGuid}
    />
  )
})
