import {
  faCreditCard,
  faHandHoldingDollar,
} from '@fortawesome/pro-light-svg-icons'
import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { default as classNames } from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { OnsiteModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { PaymentErrorCard } from '../../components/PaymentWorkflow/components/PaymentStatus/PaymentErrorCard'
import { PaymentSuccessCard } from '../../components/PaymentWorkflow/components/PaymentStatus/PaymentSuccessCard'
import { usePaymentStatusContext } from '../../components/PaymentWorkflow/providers/PaymentStatusProvider'
import Switch from '../../elements/Switch/Switch'
import useIsMobile, { useIsMediumScreen } from '../../hooks/useIsMobile'
import { useStrictContext } from '../../utils/react-utils'
import { InvoiceAlreadyPaidCard } from './InvoiceAlreadyPaidCard'
import { IssueLoading } from './NotFound'
import { SelfServeCreditCardPaymentV2 } from './SelfServeCreditCardPayment/SelfServeCreditCardPaymentV2'
import SelfServeWisetackFinancing from './SelfServeWisetackFinancing/SelfServeWisetackFinancing'
import { SelfServePaymentFormContext } from './selfServePaymentUtils'

type PaymentOptionSelectProps = {
  selectedPaymentType: SelfServePaymentOption
  selectPaymentType: (paymentType: SelfServePaymentOption) => void
}
const PaymentOptionSelect = React.memo<PaymentOptionSelectProps>(
  ({ selectPaymentType, selectedPaymentType }) => {
    return (
      <div className="mb-5 flex flex-row gap-3">
        {(
          [
            {
              icon: faCreditCard,
              description: 'Credit Card',
              paymentType: 'credit-card',
            },
            {
              icon: faHandHoldingDollar,
              description: 'Financing',
              paymentType: 'financing',
            },
          ] as const
        ).map(({ icon, description, paymentType }) => (
          <div
            key={paymentType}
            className={classNames(
              'text-md flex h-[44px] flex-1 cursor-pointer items-center space-x-2 rounded-[4px] border border-solid border-bz-gray-500 px-3 font-semibold',
              {
                'border border-solid border-bz-primary bg-daybreak-blue-100 text-bz-primary':
                  selectedPaymentType === paymentType,
              },
            )}
            onClick={() => selectPaymentType(paymentType)}
          >
            <FontAwesomeIcon className="h-[28px] w-[28px]" icon={icon} />
            <div>{description}</div>
          </div>
        ))}
      </div>
    )
  },
)
type SelfServePaymentFormProps = {
  onClose?: () => void
}

type SelfServePaymentOption = 'credit-card' | 'financing'

export const SelfServePaymentFormV2 = React.memo<SelfServePaymentFormProps>(
  ({ onClose }) => {
    const { wisetackEnabled, paymentCompleted, didPaymentSucceed } =
      useStrictContext(SelfServePaymentFormContext)

    const { paymentStatusProps, clearPaymentStatusProps } =
      usePaymentStatusContext()
    const isMobile = useIsMobile()
    const isMediumScreen = useIsMediumScreen()

    const [selectedPaymentType, setSelectedPaymentType] =
      useState<SelfServePaymentOption>('credit-card')

    const selectPaymentType = useCallback(
      (paymentType: SelfServePaymentOption) => {
        setSelectedPaymentType(paymentType)
      },
      [],
    )

    const content = useMemo(() => {
      if (paymentStatusProps?.type === 'payment-success') {
        return (
          <PaymentSuccessCard
            {...paymentStatusProps.data}
            bottomPadding={false}
          />
        )
      }

      if (
        paymentStatusProps?.type !== 'payment-processing' &&
        paymentCompleted
      ) {
        return <InvoiceAlreadyPaidCard />
      }

      return (
        <>
          {wisetackEnabled && (
            <PaymentOptionSelect
              selectedPaymentType={selectedPaymentType}
              selectPaymentType={selectPaymentType}
            />
          )}
          <Switch value={selectedPaymentType}>
            {{
              'credit-card': () => <SelfServeCreditCardPaymentV2 />,
              financing: () => <SelfServeWisetackFinancing />,
              default: () => <IssueLoading />,
            }}
          </Switch>
        </>
      )
    }, [
      paymentCompleted,
      paymentStatusProps,
      selectPaymentType,
      selectedPaymentType,
      wisetackEnabled,
    ])

    return (
      <div className="flex h-full flex-1 flex-col bg-[#F1F5F9]">
        <div
          className={classNames(
            'relative flex flex-1 flex-col items-center justify-start overflow-auto text-bz-gray-900',
            {
              'p-4': isMobile,
            },
          )}
        >
          {paymentStatusProps?.type === 'payment-error' && (
            <OnsiteModal
              drawer
              modalClassName="z-[1020]"
              onClose={clearPaymentStatusProps}
            >
              <PaymentErrorCard
                {...paymentStatusProps.data}
                onClose={clearPaymentStatusProps}
              />
            </OnsiteModal>
          )}
          {onClose && isMediumScreen && !didPaymentSucceed && (
            <Button
              ghost
              size="large"
              className={classNames(
                'flex items-center justify-center border-bz-gray-500 text-bz-gray-900',
                isMobile ? 'absolute right-4' : 'fixed right-6 top-14',
              )}
              shape="circle"
              onClick={onClose}
            >
              <FontAwesomeIcon icon={faXmark} />
            </Button>
          )}
          <div
            className={classNames('my-14 rounded-md bg-white p-6 shadow-md', {
              'w-[480px] max-w-[480px]': !isMobile,
            })}
          >
            {content}
          </div>
        </div>
      </div>
    )
  },
)
