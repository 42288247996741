import {
  PaymentMethod,
  TilledPaymentErrorDetail,
  formatMoney,
  getTilledPaymentErrorCodeSolution,
  getTilledUserFriendlyErrorMessage,
  isTilledPaymentErrorDetail,
  usdToUsCents,
} from '@breezy/shared'
import classNames from 'classnames'
import React, { useCallback, useEffect, useRef } from 'react'
import { CreditCardPaymentForm } from '../../../components/PaymentWorkflow/components/CreditCardPaymentForm/CreditCardPaymentForm'
import { getNameOfPayer } from '../../../components/PaymentWorkflow/components/TilledPaymentWorkflow/TilledPaymentWorkflow'
import {
  PaymentFormData,
  useSubmitTilledPayment,
} from '../../../components/PaymentWorkflow/hooks/useSubmitTilledPayment'
import { useTilledPaymentForm } from '../../../components/PaymentWorkflow/hooks/useTilledPaymentForm'
import { usePaymentStatusContext } from '../../../components/PaymentWorkflow/providers/PaymentStatusProvider'
import { useFetchCardsOnFile } from '../../../hooks/fetch/useFetchCardsOnFile'
import useIsMobile from '../../../hooks/useIsMobile'
import { useMessage } from '../../../utils/antd-utils'
import { useStrictContext } from '../../../utils/react-utils'
import { SelfServePaymentFormContext } from '../selfServePaymentUtils'

export const SelfServeCreditCardPaymentV2 = React.memo(() => {
  const message = useMessage()
  const { setPaymentStatusProps } = usePaymentStatusContext()

  const {
    invoice,
    setPaymentSuccessStatus,
    refetchInvoice,
    companyGuid,
    tilledMerchantId,
    tzId,
  } = useStrictContext(SelfServePaymentFormContext)
  // A ref is used to store the submitted card data so it can be used in the useEffect below
  const submittedPaymentData = useRef<
    PaymentFormData<PaymentMethod.CARD> | undefined
  >(undefined)
  const submittedPaymentRecordGuid = useRef<string | undefined>(undefined)
  const submittedPaymentAmountUsd = useRef<number | undefined>(undefined)
  const {
    cardNumberElement,
    cardExpirationElement,
    cardCvvElement,
    loadingTilled,
    tilledError,
    tilledFormInfo,
    onSubmitValidationCheck,
  } = useTilledPaymentForm(PaymentMethod.CARD)

  const {
    data: cardsOnFile,
    refetch: refetchCardsOnFile,
    fetching: isLoadingCardsOnFile,
  } = useFetchCardsOnFile(invoice.accountGuid)

  const onSubmitPaymentError = useCallback(
    (error: TilledPaymentErrorDetail | Error) => {
      const submittedData = submittedPaymentData.current
      if (!submittedData) return
      console.error(error)
      const isTilledError = isTilledPaymentErrorDetail(error)

      if (isTilledError) {
        // Show the Payment Error Card w/ the tilled payment info and solution if there is one
        setPaymentStatusProps({
          type: 'payment-error',
          data: {
            nameOfPayer: getNameOfPayer(submittedData),
            paymentAmountUsc: usdToUsCents(
              invoice.paymentsSummary.amountDueUsd,
            ),
            paymentMethod: PaymentMethod.CARD,
            errorMessage: getTilledUserFriendlyErrorMessage(error?.message),
            errorCode: error.code,
            solution: getTilledPaymentErrorCodeSolution(
              PaymentMethod.CARD,
              error.code,
            ),
          },
        })
      } else {
        // Show the Payment Error Card
        setPaymentStatusProps({
          type: 'payment-error',
          data: {
            nameOfPayer: getNameOfPayer(submittedData),
            paymentAmountUsc: usdToUsCents(
              invoice.paymentsSummary.amountDueUsd,
            ),
            paymentMethod: PaymentMethod.CARD,
            errorMessage: error?.message,
          },
        })
      }
      refetchInvoice()
    },
    [
      invoice.paymentsSummary.amountDueUsd,
      refetchInvoice,
      setPaymentStatusProps,
    ],
  )

  const {
    onSubmit,
    isLoading: submittingPayment,
    didSucceed,
  } = useSubmitTilledPayment({
    tilledFormInfo,
    invoice,
    paymentAmountUsc: usdToUsCents(invoice.paymentsSummary.amountDueUsd),
    accountGuid: invoice.accountGuid,
    paymentMethod: PaymentMethod.CARD,
    onError: onSubmitPaymentError,
    links: invoice.links,
  })

  const onSubmitInner = useCallback(
    async (data: PaymentFormData<PaymentMethod.CARD>) => {
      setPaymentStatusProps({
        type: 'payment-processing',
        data: {
          paymentMethod: PaymentMethod.CARD,
        },
      })
      submittedPaymentData.current = data
      submittedPaymentAmountUsd.current = invoice.paymentsSummary.amountDueUsd
      submittedPaymentRecordGuid.current = await onSubmit(
        { ...data },
        companyGuid,
        tilledMerchantId,
        'self-serve',
      )
      refetchInvoice()
      refetchCardsOnFile()
    },
    [
      setPaymentStatusProps,
      invoice.paymentsSummary.amountDueUsd,
      onSubmit,
      companyGuid,
      tilledMerchantId,
      refetchInvoice,
      refetchCardsOnFile,
    ],
  )

  useEffect(() => {
    if (didSucceed) {
      const submittedData = submittedPaymentData.current
      const paymentRecordGuid = submittedPaymentRecordGuid.current
      const paymentAmountUsd = submittedPaymentAmountUsd.current
      if (!submittedData || !paymentRecordGuid || !paymentAmountUsd) return
      // Show the Payment Completed Card
      setPaymentStatusProps({
        type: 'payment-success',
        data: {
          nameOfPayer: getNameOfPayer(submittedData),
          paymentAmountUsc: usdToUsCents(paymentAmountUsd),
          paymentMethod: PaymentMethod.CARD,
          invoiceDisplayId: invoice.displayId,
          emailAddressLinks: {
            accountGuid: invoice.accountGuid,
            jobGuid: invoice.links.jobGuid,
          },
          paymentRecordGuid,
          tzId,
          companyGuid,
        },
      })

      refetchCardsOnFile()
    }
    setPaymentSuccessStatus(didSucceed)
  }, [
    companyGuid,
    didSucceed,
    invoice.accountGuid,
    invoice.displayId,
    invoice.links.jobGuid,
    refetchCardsOnFile,
    setPaymentSuccessStatus,
    setPaymentStatusProps,
    tzId,
  ])

  const isLoading = loadingTilled || submittingPayment || isLoadingCardsOnFile

  useEffect(() => {
    if (!loadingTilled && tilledError) {
      console.error(tilledError)
      message.error('Something went wrong. Try again later.')
    }
  }, [loadingTilled, message, tilledError])
  const isMobile = useIsMobile()

  return (
    <CreditCardPaymentForm
      primaryButtonText={`Pay ${formatMoney(
        invoice.paymentsSummary.amountDueUsd,
      )}`}
      isLoading={isLoading}
      onSubmitValidationCheck={onSubmitValidationCheck}
      onSubmit={data => onSubmitInner(data)}
      formStyle="inline"
      withSendEmail
      footerClassName={classNames(
        isMobile ? 'mx-[-16px] px-4' : 'mx-[-24px] px-6',
      )}
      withSavePaymentMethod
      cardsOnFile={cardsOnFile}
      tzId={tzId}
    >
      <div>
        {cardNumberElement}
        <div className="flex flex-row space-x-3">
          {cardExpirationElement}
          {cardCvvElement}
        </div>
      </div>
    </CreditCardPaymentForm>
  )
})
