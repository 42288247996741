import React from 'react'
import { useParams } from 'react-router-dom'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { useIsLdLoaded } from '../../hooks/useFeatureFlags'
import { useIsSmallScreen } from '../../hooks/useIsMobile'
import { NotFound } from './NotFound'
import { SelfServePaymentForm } from './SelfServePaymentForm'
import { SelfServePaymentInvoice } from './SelfServePaymentInvoice'
import './SelfServePaymentPage.less'
import { SelfServePaymentPayButtonFooter } from './SelfServePaymentPayButtonFooter'
import { SelfServePaymentProvider } from './selfServePaymentUtils'

export const SelfServePaymentPage = React.memo(() => {
  const { invoiceReferenceNumber } = useParams<{
    invoiceReferenceNumber?: string
  }>()

  const { contactGuid } = useParams<{ contactGuid?: string }>()

  const isSmallScreen = useIsSmallScreen()
  const ldLoaded = useIsLdLoaded()

  if (!ldLoaded) {
    return <LoadingSpinner />
  }

  if (!invoiceReferenceNumber) {
    return <NotFound />
  }

  return (
    <div className="self-serve-payment-page flex h-screen w-screen flex-col">
      <SelfServePaymentProvider
        invoiceReferenceNumber={invoiceReferenceNumber}
        contactGuid={contactGuid}
      >
        <div className="flex h-full flex-row text-bz-gray-1000">
          <SelfServePaymentInvoice />
          {isSmallScreen ? (
            <SelfServePaymentPayButtonFooter />
          ) : (
            <SelfServePaymentForm />
          )}
        </div>
      </SelfServePaymentProvider>
    </div>
  )
})
