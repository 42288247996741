import {
  PaymentMethod,
  TilledPaymentErrorDetail,
  formatMoney,
  getTilledUserFriendlyErrorMessage,
  usdToUsCents,
} from '@breezy/shared'
import classNames from 'classnames'
import React, { useCallback, useEffect } from 'react'
import { CreditCardPaymentForm } from '../../../components/PaymentWorkflow/components/CreditCardPaymentForm/CreditCardPaymentForm'
import { useSubmitTilledPayment } from '../../../components/PaymentWorkflow/hooks/useSubmitTilledPayment'
import { useTilledPaymentForm } from '../../../components/PaymentWorkflow/hooks/useTilledPaymentForm'
import { useFetchCardsOnFile } from '../../../hooks/fetch/useFetchCardsOnFile'
import useIsMobile from '../../../hooks/useIsMobile'
import { useMessage } from '../../../utils/antd-utils'
import { useStrictContext } from '../../../utils/react-utils'
import { SelfServePaymentFormContext } from '../selfServePaymentUtils'

export const SelfServeCreditCardPayment = React.memo(() => {
  const message = useMessage()

  const {
    invoice,
    setPaymentSuccessStatus,
    refetchInvoice,
    companyGuid,
    tilledMerchantId,
    tzId,
  } = useStrictContext(SelfServePaymentFormContext)

  const {
    cardNumberElement,
    cardExpirationElement,
    cardCvvElement,
    loadingTilled,
    tilledError,
    tilledFormInfo,
    onSubmitValidationCheck,
  } = useTilledPaymentForm(PaymentMethod.CARD)

  const {
    data: cardsOnFile,
    refetch: refetchCardsOnFile,
    fetching: isLoadingCardsOnFile,
  } = useFetchCardsOnFile(invoice.accountGuid)

  const onPaymentError = useCallback(
    (err: Error | TilledPaymentErrorDetail) => {
      console.error(err)
      message.error(getTilledUserFriendlyErrorMessage((err as Error)?.message))
      refetchInvoice()
    },
    [message, refetchInvoice],
  )
  const {
    onSubmit,
    isLoading: submittingPayment,
    didSucceed,
  } = useSubmitTilledPayment({
    tilledFormInfo,
    invoice,
    paymentAmountUsc: usdToUsCents(invoice.paymentsSummary.amountDueUsd),
    accountGuid: invoice.accountGuid,
    paymentMethod: PaymentMethod.CARD,
    onError: onPaymentError,
    links: invoice.links,
  })

  useEffect(() => {
    setPaymentSuccessStatus(didSucceed)
    refetchCardsOnFile()
  }, [didSucceed, refetchCardsOnFile, setPaymentSuccessStatus])

  const isLoading = loadingTilled || submittingPayment || isLoadingCardsOnFile

  useEffect(() => {
    if (!loadingTilled && tilledError) {
      console.error(tilledError)
      message.error('Something went wrong. Try again later.')
    }
  }, [loadingTilled, message, tilledError])
  const isMobile = useIsMobile()

  return (
    <CreditCardPaymentForm
      primaryButtonText={`Pay ${formatMoney(
        invoice.paymentsSummary.amountDueUsd,
      )}`}
      isLoading={isLoading}
      onSubmitValidationCheck={onSubmitValidationCheck}
      onSubmit={data =>
        onSubmit(
          {
            ...data,
          },
          companyGuid,
          tilledMerchantId,
          'self-serve',
        )
      }
      formStyle="inline"
      withSendEmail
      footerClassName={classNames(
        isMobile ? 'mx-[-16px] px-4' : 'mx-[-24px] px-6',
      )}
      withSavePaymentMethod
      cardsOnFile={cardsOnFile}
      tzId={tzId}
    >
      <div>
        {cardNumberElement}
        <div className="flex flex-row space-x-3">
          {cardExpirationElement}
          {cardCvvElement}
        </div>
      </div>
    </CreditCardPaymentForm>
  )
})
